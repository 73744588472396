<template>
  <v-flex xs12 lg3 sm4 md3>
    <v-card
      v-sticky="shouldStick"
      class="mx-auto elevation-1"
      width="100%"
      sticky-offset="offset"
      sticky-side="both"
      sticky-z-index="Zindex"
    >
      <v-card-text class="text-xs-center">
        <div class="mr-2 mb-3">
          <AvatarLetter :text="store.name" :size="100" :text-class="'white--text display-1'" color="secondary" />
        </div>
        <div class="display-1 font-weight-regular" v-text="store.name"></div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn block depressed color="secondary" large @click="searchStatesSchedule">
          <v-icon left> access_time </v-icon> Estado del dia de hoy
          <v-icon right> access_time </v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-list class="ma-0 py-0" :dense="$mq !== 'lg'">
        <template v-for="(item, i) in items">
          <v-divider v-if="item.divider" :key="i" dark class="my-3"></v-divider>
          <v-list-tile
            v-else-if="checkRoute(item)"
            :key="i"
            :class="$route.name === item.route ? 'active' : ''"
            @click="goRoute(item)"
          >
            <v-list-tile-action class="mr-0 pl-0">
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="grey--text">
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider v-if="i + 1 !== items.length && checkRoute(item)" :key="`div-${i}`"></v-divider>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon color="accent" target="_blank" href="https://rosalinda.cl/app/reparto.apk">
          <v-icon small>smartphone</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog.statusStore" :fullscreen="$mq !== 'lg'" max-width="800px" scrollable>
      <v-card>
        <TitleDialog title="Estado del dia de hoy">
          <template slot="icons">
            <v-btn flat icon @click.native="dialog.statusStore = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </TitleDialog>
        <v-divider></v-divider>
        <v-card-text v-if="listStateStatus.length > 0" style="height: 500px;">
          <v-layout wrap class="body-2">
            <v-flex xs6 class="pb-2 pt-2">
              <v-checkbox v-model="all" hide-details class="ma-0">
                <template slot="label"
                  ><span class="subheading font-weight-bold">Comuna</span>
                </template>
              </v-checkbox>
            </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2">
              Hora Cierre
            </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2">
              Estado
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <template v-for="(state, index) in listStateStatus">
            <v-layout :key="index" wrap justify-center align-center>
              <v-flex xs6>
                <v-checkbox v-model="selectedCommunesClose" :value="state" hide-details class="ma-0">
                  <template slot="label">
                    {{ state.name }}
                    <span class="caption ml-1"> ({{ state.value | toPrice }})</span>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex xs3>
                <span v-if="!state.shipping"
                  ><v-chip label color="error" text-color="white" class="ma-0" small>
                    {{ state.timeFormat }} hrs
                  </v-chip></span
                >
                <span v-else>
                  <v-chip label color="warning" text-color="black" class="ma-0" small
                    >En {{ $moment.unix(state.time).toNow(true) }} se baja</v-chip
                  >
                </span>
              </v-flex>
              <v-flex xs3>
                <v-chip v-if="state.shipping" color="success" text-color="white" class="my-2">
                  <v-avatar>
                    <v-icon>check_circle</v-icon>
                  </v-avatar>
                  Abierto
                </v-chip>
                <v-chip v-if="!state.shipping && !state.shipping_fecha" color="error" text-color="white" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Horario
                </v-chip>
                <v-chip v-if="!state.shipping && state.shipping_fecha" color="warning" text-color="white" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Manual
                </v-chip>
                <v-chip v-if="state.shipping === null" color="warning" text-color="white" class="my-2">
                  <v-icon left>remove_circle</v-icon>
                  Limitado
                </v-chip>
              </v-flex>
            </v-layout>
            <v-divider v-if="listStateStatus.length !== index + 1" :key="`d-${index}`"></v-divider>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="selectedCommunesClose.length === 0"
            color="error"
            depressed
            @click.native="updateSchedule('close')"
            >Cerrar</v-btn
          >
          <v-btn
            :disabled="selectedCommunesClose.length === 0"
            color="success"
            depressed
            @click.native="updateSchedule('open')"
            >Abrir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { GET_STATUS_STORE } from '../../config'
import AvatarLetter from '../general/avatarLetter.vue'
import TitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'OrderNav',
  components: { AvatarLetter, TitleDialog },
  props: ['goRoute', 'store'],
  data() {
    return {
      all: false,
      Zindex: 1,
      offset: { top: 86, bottom: 0 },
      items: [
        { icon: 'home', text: 'Inicio', route: 'storeHome' },
        { icon: 'settings', text: 'General', route: 'storeSettings' },
        { icon: 'settings', text: 'Configuracion', route: 'storeZone' },
        {
          icon: 'date_range',
          text: 'Horarios diarios por defecto',
          route: 'storeSchedule'
        },
        {
          icon: 'event_busy',
          text: 'Horarios especiales por día',
          route: 'storeBlockSchedule'
        },
        { icon: 'local_florist', text: 'Diseñadores', route: 'storeFlowers' },
        { icon: 'local_shipping', text: 'Repartidores', route: 'storeDealers' },
        { icon: 'stores', text: 'Comunas', route: 'storeStates' },
        // { icon: 'settings', text: 'General', route: 'storeSettings' },
        { icon: 'whatshot', text: 'Alta Demanda', route: 'storeDailySale' },
        { icon: 'graphic_eq', text: 'Estadisticas', route: 'storeStatistics' },
        {
          icon: 'monetization_on',
          text: 'Liquidación de pago',
          route: 'storePayStore'
        },
        {
          icon: 'monetization_on',
          text: 'Liquidación Mensual Tiendas',
          route: 'storePayStoreAdmin'
        }
      ],
      dialog: {
        statusStore: false
      },
      listStateStatus: [],
      selectedCommunesClose: []
    }
  },
  computed: {
    shouldStick() {
      return this.$mq !== 'xs'
    },
    comments() {
      return this.$store.getters.getComments
    },
    states() {
      return this.$store.getters.getStates
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    all(e) {
      if (e) {
        this.listStateStatus.forEach(element => {
          this.selectedCommunesClose.push(element)
        })
      } else {
        this.selectedCommunesClose = []
      }
    }
  },
  mounted() {
    console.log(window.innerHeight)
  },
  methods: {
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: false
          }
        })
        this.listStateStatus = res.data
        this.selectedCommunesClose = []
        this.dialog.statusStore = true
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateSchedule(type) {
      if (this.selectedCommunesClose.length > 0) {
        try {
          await this.$http.post(GET_STATUS_STORE, {
            type,
            communes: this.selectedCommunesClose
          })
          this.searchStatesSchedule()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    checkRoute(item) {
      const { user } = this
      if (item.text === 'Configuracion' && !user.store.admin) {
        return false
      }
      if (item.route === 'storeDailySale' && !user.store.admin) {
        return false
      }
      if (item.route === 'storePayStore' && user.shop.admin) {
        return false
      }
      if (item.route === 'storePayStoreAdmin' && !user.shop.admin) {
        return false
      }
      if (item.route === 'storePayStoreAdmin' && user.role.id !== 1) {
        return false
      }
      return true
    }
  }
}
</script>

<style>
strong {
  font-weight: 600;
}
</style>
