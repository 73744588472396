<template>
  <v-content sticky-container>
    <v-container grid-list-lg fluid>
      <v-layout
        v-if="_.size(store) > 0"
        wrap
        align-top
        justify-left
        fill-height
      >
      <StoreNav :go-route="goRoute" :store="store"></StoreNav>
        <v-flex xs12 lg9 sm8 md9>
          <router-view :store="store" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import StoreNav from './nav.vue'
import { MI_STORE } from '../../config'

export default {
  name: 'Store',
  components: { StoreNav },
  metaInfo: {
    title: 'Mi tienda'
  },
  data: () => ({
    store: {}
  }),
  mounted() {
    this.getStore()
  },
  methods: {
    async getStore() {
      try {
        const res = await this.$http.get(`${MI_STORE}/home`)
        this.store = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    goRoute(item) {
      this.$router.push({ name: item.route })
    }
  }
}
</script>

<style></style>
